import { takeLatest } from "redux-saga/effects";
import * as ACTION from "./actions";
import { createSaga } from "../sagas";

export function* updateCompanySaga() {
  yield takeLatest(
    ACTION.UPDATE_COMPANY_REQUEST,
    createSaga("UPDATE_COMPANY", "/company", "PUT")
  );
}

export function* getCompaniesSaga() {
  yield takeLatest(
    ACTION.GET_COMPANIES_REQUEST,
    createSaga("GET_COMPANIES", "/company", "GET")
  );
}

export function* searchCompaniesSaga() {
  yield takeLatest(
    ACTION.SEARCH_COMPANIES_REQUEST,
    createSaga("SEARCH_COMPANIES", "/company", "GET")
  );
}

export function* getCompanyInfoSaga() {
  yield takeLatest(
    ACTION.GET_COMPANY_INFO_REQUEST,
    createSaga("GET_COMPANY_INFO", "/company/search", "GET")
  );
}

export function* addCompanySaga() {
  yield takeLatest(
    ACTION.ADD_COMPANY_REQUEST,
    createSaga("ADD_COMPANY", "/company", "POST")
  );
}

export function* deleteCompanySaga() {
  yield takeLatest(
    ACTION.DELETE_COMPANY_REQUEST,
    createSaga("DELETE_COMPANY", "/company", "DELETE")
  );
}
