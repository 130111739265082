export const UPDATE_POINTS_REQUEST = "UPDATE_POINTS_REQUEST";
export const UPDATE_POINTS_SUCCESS = "UPDATE_POINTS_SUCCESS";
export const UPDATE_POINTS_FAILURE = "UPDATE_POINTS_FAILURE";
export const GET_POINTS_REQUEST = "GET_POINTS_REQUEST";
export const GET_POINTS_SUCCESS = "GET_POINTS_SUCCESS";
export const GET_POINTS_FAILURE = "GET_POINTS_FAILURE";
export const SEARCH_POINTS_REQUEST = "SEARCH_POINTS_REQUEST";
export const SEARCH_POINTS_SUCCESS = "SEARCH_POINTS_SUCCESS";
export const SEARCH_POINTS_FAILURE = "SEARCH_POINTS_FAILURE";
export const ADD_POINTS_REQUEST = "ADD_POINTS_REQUEST";
export const ADD_POINTS_SUCCESS = "ADD_POINTS_SUCCESS";
export const ADD_POINTS_FAILURE = "ADD_POINTS_FAILURE";
export const DELETE_POINTS_REQUEST = "DELETE_POINTS_REQUEST";
export const DELETE_POINTS_SUCCESS = "DELETE_POINTS_SUCCESS";
export const DELETE_POINTS_FAILURE = "DELETE_POINTS_FAILURE";

export const updatePoints = (id: number, data: any) => {
  return {
    method: "PUT",
    type: UPDATE_POINTS_REQUEST,
    data,
    id,
  };
};

export const addPoints = (data?: object) => {
  return {
    method: "POST",
    type: ADD_POINTS_REQUEST,
    data,
  };
};

export const getPoints = () => {
  return {
    type: GET_POINTS_REQUEST,
  };
};

export const searchPoints = (query: string | undefined) => {
  return {
    method: "GET",
    type: SEARCH_POINTS_REQUEST,
    id: `?query=${query}`,
  };
};

export const deletePoints = (id: number) => {
  return {
    method: "DELETE",
    type: DELETE_POINTS_REQUEST,
    id,
  };
};
