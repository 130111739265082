import { takeLatest } from "redux-saga/effects";
import * as ACTION from "./actions";
import { createSaga } from "../sagas";

export function* getUsersSaga() {
  yield takeLatest(
    ACTION.FETCH_USERS_REQUEST,
    createSaga("FETCH_USERS", "/users")
  );
}

export function* searchUsersSaga() {
  yield takeLatest(
    ACTION.SEARCH_USERS_REQUEST,
    createSaga("SEARCH_USERS", "/users")
  );
}

export function* getUsersTagsSaga() {
  yield takeLatest(
    ACTION.GET_USERS_TAGS_REQUEST,
    createSaga("GET_USERS_TAGS", "/tags")
  );
}
