import React from "react";

import "./detailcol.scss";

interface IDetail {
  heading: string;
  content?: any;
  col?: string;
  icon?: any;
}

const DetailCol: React.FC<IDetail> = ({
  heading,
  content = "noinfo",
  col = "3",
  icon
}) => {
  return (
    <div className={`col-md-${col}`}>
      <div className="detail-col">
        {icon && <img src={icon} />}
        <div className="detail-col__content">
          <span className="clabel">{heading}</span>
          <span className="content">{content}</span>
        </div>
      </div>
    </div>
  );
};

export default DetailCol;
