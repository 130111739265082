import * as ACTION from "./actions";
import { DefaultApiResult, DefaultApiAction } from "../reducers";
import { REHYDRATE } from "redux-persist/lib/constants";

export const INIT_STATE = {
  loading: false,
  error: false,
  data: [],
};

export default function reducer(
  state: DefaultApiResult = INIT_STATE,
  action: DefaultApiAction
): DefaultApiResult {
  if (state === undefined) {
    return INIT_STATE;
  }
  switch (action.type) {
    case ACTION.ACTIVATE_USER_REQUEST:
    case ACTION.BLOCK_USER_REQUEST:
    case ACTION.UPDATE_USER_REQUEST:
    case ACTION.GET_USER_REQUEST:
    case ACTION.DELETE_USER_REQUEST:
    case ACTION.ADD_USER_TAG_REQUEST:
    case ACTION.DELETE_USER_REQUEST:
    case ACTION.DELETE_USER_POS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ACTION.ACTIVATE_USER_SUCCESS:
    case ACTION.UPDATE_USER_SUCCESS:
    case ACTION.GET_USER_SUCCESS:
    case ACTION.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };

    case ACTION.SELECT_USER:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.data,
      };

    case ACTION.BLOCK_USER_SUCCESS:
    case ACTION.ADD_USER_TAG_SUCCESS:
    case ACTION.DELETE_USER_SUCCESS:
    case ACTION.DELETE_USER_POS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.ACTIVATE_USER_FAILURE:
    case ACTION.BLOCK_USER_FAILURE:
    case ACTION.UPDATE_USER_FAILURE:
    case ACTION.DELETE_USER_FAILURE:
    case ACTION.ADD_USER_TAG_FAILURE:
    case ACTION.DELETE_USER_FAILURE:
    case ACTION.DELETE_USER_POS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.errors,
      };
    default:
      return state;
  }
}
