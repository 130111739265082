export const ACTIVATE_USER_REQUEST = "ACTIVATE_USER_REQUEST";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_FAILURE = "ACTIVATE_USER_FAILURE";

export const activateUser = (id: number) => {
  return {
    method: "PUT",
    type: ACTIVATE_USER_REQUEST,
    id
  };
};

export const SELECT_USER = "SELECT_USER";

export const selectedUser = (data: object) => {
  return {
    type: SELECT_USER,
    data
  };
};

export const BLOCK_USER_REQUEST = "BLOCK_USER_REQUEST";
export const BLOCK_USER_SUCCESS = "BLOCK_USER_SUCCESS";
export const BLOCK_USER_FAILURE = "BLOCK_USER_FAILURE";

export const blockUser = (id: number) => {
  return {
    method: "PUT",
    type: BLOCK_USER_REQUEST,
    id
  };
};

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const updateUser = (id: number, data: any) => {
  return {
    method: "PUT",
    type: UPDATE_USER_REQUEST,
    data,
    id
  };
};

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const getUser = (id: number) => {
  return {
    method: "GET",
    type: GET_USER_REQUEST,
    id
  };
};

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const deleteUser = (id: number) => {
  return {
    method: "DELETE",
    type: DELETE_USER_REQUEST,
    id
  };
};

export const ADD_USER_TAG_REQUEST = "ADD_USER_TAG_REQUEST";
export const ADD_USER_TAG_SUCCESS = "ADD_USER_TAG_SUCCESS";
export const ADD_USER_TAG_FAILURE = "ADD_USER_TAG_FAILURE";

export const addUserTag = (id: number, data: any) => {
  return {
    method: "PUT",
    type: ADD_USER_TAG_REQUEST,
    id,
    data
  };
};

export const DELETE_USER_TAG_REQUEST = "DELETE_USER_TAG_REQUEST";
export const DELETE_USER_TAG_SUCCESS = "DELETE_USER_TAG_SUCCESS";
export const DELETE_USER_TAG_FAILURE = "DELETE_USER_TAG_FAILURE";

export const deleteUserTag = (id: number | undefined) => {
  return {
    method: "DELETE",
    type: DELETE_USER_TAG_REQUEST,
    id
  };
};

export const DELETE_USER_POS_REQUEST = "DELETE_USER_POST_REQUEST";
export const DELETE_USER_POS_SUCCESS = "DELETE_USER_POST_SUCCESS";
export const DELETE_USER_POS_FAILURE = "DELETE_USER_POST_FAILURE";

export const deleteUserPos = (id: number | undefined) => {
  return {
    method: "DELETE",
    type: DELETE_USER_POS_REQUEST,
    id
  };
};
