import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, FormikErrors } from "formik";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { updatePoints } from "../../redux/points/actions";
import { RootState, DefaultApiResult } from "../../redux/reducers";
import displayMessage from "../../helpers/message";
import "./points.scss";

interface IUserForm {
  point?: any;
  close: () => void;
}

interface MyFormValues {
  email: string;
}

const PointsForm: React.FC<IUserForm> = ({ close, point }) => {
  console.log(point);
  const userObj = {
    company: point?.company?.name || "",
    street: point?.address?.street || "",
    buildingNum: point?.address?.buildingNumber || "",
    apartmentNum: point?.address?.apartmentNumber || "",
    postCode: point?.address?.postCode || "",
    city: point?.address?.city || "",
    county: point?.address?.county || "",
  };

  const [formValues, setFromValues] = useState(userObj);

  const dispatch: Dispatch = useDispatch();
  const reduxUser = useSelector<RootState, DefaultApiResult>(
    (state) => state.User
  );

  const reduxPoints = useSelector<RootState, DefaultApiResult>(
    (state) => state.Points
  );

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="user-form">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <h1>
            {!!point ? "Edytuj punkt sprzedaży" : "Dodaj punkt sprzedaży"}
          </h1>
        </div>
      </div>

      <Formik
        initialValues={formValues}
        enableReinitialize
        validate={(values) => {
          const errors: FormikErrors<MyFormValues> = {};

          return errors;
        }}
        onSubmit={(values: any, { setSubmitting }) => {
          const companyadress = {
            city: values.city,
            street: values.street,
            buildingNumber: values.buildingNum,
            apartmentNumber: values.apartmentNum,
            postCode: values.postCode,
            county: values.county,
          };

          !!point &&
            dispatch(
              updatePoints(point.id, {
                ...point,
                address: companyadress,
              })
            );

          setSubmitting(false);
          close();
        }}
      >
        {({ isSubmitting, values }) => (
          <Form className="company-form__form">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <fieldset>
                  <legend>Wybierz firmę</legend>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="input-field">
                        <label htmlFor="company">Wybierz firmę</label>
                        <Field type="text" name="company" />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <legend>Lokalizacja</legend>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-field">
                        <label htmlFor="street">Ulica</label>
                        <Field type="text" name="street" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-field">
                        <label htmlFor="buildingNumber">Numer budynku</label>
                        <Field type="email" name="buildingNumber" />
                        <ErrorMessage name="email" component="div" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-field">
                        <label htmlFor="apartmentNumber">Numer lokalu</label>
                        <Field type="text" name="apartmentNumber" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-field">
                        <label htmlFor="city">Miasto</label>
                        <Field type="text" name="city" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field">
                        <label htmlFor="postCode">Kod pocztowy</label>
                        <Field type="text" name="postCode" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-field">
                        <label htmlFor="county">Powiat</label>
                        <Field type="text" name="county" />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <Button
                  onClick={() => close()}
                  type="button"
                  className="tag big"
                >
                  Anuluj
                </Button>
                <Button
                  loading={reduxUser.loading}
                  disabled={isSubmitting}
                  type="submit"
                >
                  {!!point ? "Zapisz zmiany" : "Dodaj"}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PointsForm;
