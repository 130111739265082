import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { RootState, AuthApiResult } from "../../redux/reducers";
import { useSelector } from "react-redux";
import useLocalStorage from "../../hooks/useLocalStorage";
// routes
import routes from "./routes";
// pages
import Users from "../../pages/users";
import User from "../../pages/user";
import Login from "../../pages/login";
import Companies from "../../pages/companies";
import Points from "../../pages/points";
import Reports from "../../pages/reports";

const Routes = () => {
  const { users, user, login, companies, points, reports } = routes;

  const [item] = useLocalStorage("auth", "");
  const auth: AuthApiResult = useSelector<RootState, AuthApiResult>(
    (state) => state.Auth
  );

  const PrivateRoute = ({ component: Component, ...rest }: any) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          auth.token ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: login,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  };

  return (
    <Switch>
      <Route path={login} component={Login} />
      <PrivateRoute exact path={users} component={Users} />
      <PrivateRoute path={user} component={User} />
      <PrivateRoute path={points} component={Points} />
      <PrivateRoute path={companies} component={Companies} />
      <PrivateRoute path={reports} component={Reports} />
      <Route path="" strict extact>
        <pre>home</pre>
      </Route>
    </Switch>
  );
};

export default Routes;
