import React, { useState, useEffect } from "react";
import withMainLayout from "./../../layouts/index";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState, DefaultApiResult } from "../../redux/reducers";
import {
  blockUser,
  getUser,
  addUserTag,
  UPDATE_USER_SUCCESS,
  GET_USER_SUCCESS,
  ADD_USER_TAG_SUCCESS,
  ACTIVATE_USER_SUCCESS,
  deleteUserPos,
  DELETE_USER_POS_SUCCESS
} from "../../redux/user/actions";
import UserForm from "../../layouts/UserForm";
import { eventBus } from "../../helpers/eventBus";
import { UPDATE_COMPANY_SUCCESS } from "../../redux/companies/actions";
import Swal from "sweetalert2";
import { withSwalInstance } from "sweetalert2-react";
import withReactContent from "sweetalert2-react-content";
import Back from "../../components/Back";
import Status from "../../components/Status";
import AddTags from "../../layouts/AddTags";
import DetailCol from "../../components/DetailCol";
import phoneIcon from "../../../images/phone.svg";
import emailIcon from "../../../images/email.svg";
import pinIcon from "../../../images/pin.svg";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal";
import "./user.scss";

const MySwal = withReactContent(Swal);

const User: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const dispatch: Dispatch = useDispatch();
  const reduxUser = useSelector<RootState, DefaultApiResult>(
    state => state.User
  );
  const reduxCompany = useSelector<RootState, DefaultApiResult>(
    state => state.Companies
  );

  const id = parseInt(
    window.location.href.split("/")[window.location.href.split("/").length - 1]
  );

  useEffect(() => {
    dispatch(getUser(id));
    eventBus(GET_USER_SUCCESS).then((payload: any) => {
      if (payload) {
        setUser(payload);
      }
    });
  }, [dispatch, id]);

  useEffect(() => {
    eventBus(UPDATE_COMPANY_SUCCESS).then((payload: any) => {
      if (payload) {
        setUser({ ...user, company: payload });
      }
    });
  }, [reduxCompany.data, user]);

  useEffect(() => {
    eventBus(UPDATE_USER_SUCCESS).then((payload: any) => {
      if (payload) {
        setUser(payload);
      }
    });
  }, [reduxUser.data]);

  const handleAddNew = () => {
    return withSwalInstance(
      MySwal.fire({
        buttonsStyling: false,
        title: "Wprowadź nowy tag",
        icon: "info",
        input: "text",
        showCancelButton: true,
        confirmButtonText: "Dodaj",
        cancelButtonText: "Anuluj"
      }).then(result => {
        if (result.value) {
          const tag = { id: id, name: result.value };
          dispatch(addUserTag(id, tag));
          eventBus(ADD_USER_TAG_SUCCESS).then((payload: any) => {
            if (payload) {
              setUser({ ...user, tags: [...user.tags, tag] });
              Swal.fire("Tag został dodany", "", "success");
            }
          });
        }
      })
    );
  };

  const handleDeletePos = (id: number | undefined) => {
    return withSwalInstance(
      MySwal.fire({
        buttonsStyling: false,
        title: "Czy na pewno chcesz usunąć punkt sprzedaży?",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Tak, usuń",
        cancelButtonText: "Anuluj"
      }).then(result => {
        if (result.value) {
          dispatch(deleteUserPos(id));
          eventBus(DELETE_USER_POS_SUCCESS).then(
            Swal.fire("Punkt sprzedaży usunięty", "", "success")
          );
        }
      })
    );
  };

  const columns: Array<string> = [
    "ID",
    "Nazwa firmy",
    "NIP",
    "Nazwa punktu",
    "Adres",
    "Pracownicy",
    "Data zgłoszenia",
    "Operacje"
  ];

  return (
    <article className="user-details">
      {!!user && (
        <>
          <header className="user-details__header">
            <div>
              <Back loading={reduxUser.loading} />
              <h1>
                {user?.firstName} {user?.lastName} {user?.email}
              </h1>
            </div>
            <Status
              locked={user.locked}
              text={
                user.locked ? "Użytkownik nieaktywny" : "Użytkownik aktywny"
              }
            />
          </header>
          <div className="user-details__actions">
            {/* <span className="spanbtn">Przypisz użytkownika do konkursu</span> */}
            <span
              className="spanbtn"
              onClick={() => dispatch(blockUser(user?.id))}
            >
              Zablokuj użytkownika
            </span>
            <span className="spanbtn" onClick={() => setOpenModal(true)}>
              Edytuj użytkownika
            </span>
          </div>
          <AddTags tags={user.tags} addNew={() => handleAddNew()} />

          <div className="user-details__details">
            <h3>Dane osobowe</h3>
            <div className="row">
              <DetailCol col={"2"} heading="Imię" content={user.firstName} />
              <DetailCol
                col={"2"}
                heading="Nazwisko"
                content={user?.lastName}
              />
              <DetailCol col={"2"} heading="Data zgłoszenia" />
              <DetailCol col={"2"} heading="Firma" />
            </div>
            <div className="row">
              <DetailCol
                col={"2"}
                heading="Numer telefonu"
                content={user?.company?.phoneNumber}
                icon={phoneIcon}
              />
              <DetailCol
                col={"2"}
                heading="Email"
                content={user?.email}
                icon={emailIcon}
              />
              <DetailCol col={"2"} heading="Adres" icon={pinIcon} />
            </div>
          </div>

          <div className="user-details__settlement">
            <h3>Dane do rozliczeń</h3>
            <div className="row">
              <DetailCol
                heading="Firma"
                content={user?.company?.name}
                col="2"
              />
              <DetailCol heading="NIP" content={user?.company?.nip} col="2" />
              <DetailCol
                heading="Adres"
                content={user?.company?.headquarterAddress?.formattedAddress}
                col="2"
              />

              <DetailCol
                heading="Płatnik VAT"
                content={user?.company?.vatTaxesPayer}
                col="2"
              />
              <DetailCol
                heading="Numer konta bankowego"
                content={user?.company?.bankAccountNumber}
                col="2"
              />
            </div>
          </div>
          {user?.company?.salePoints && user?.company?.salePoints.length > 0 && (
            <div className="user-details__areas">
              <div className="user-details__areas_header">
                <h3>Punkty sprzedaży</h3>
                <Button className="aqua" onClick={() => alert("drugi etap")}>
                  Przypisz
                </Button>
              </div>

              <table className="table">
                <thead>
                  <tr className="clabel">
                    {columns.map((column: any) => {
                      return <th>{column}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {user?.company?.salePoints.map((row: any) => (
                    <tr>
                      <td className="content">{row.id}</td>
                      <td className="content">{row.name}</td>
                      <td className="content">{user?.company?.nip}</td>
                      <td className="content"></td>
                      <td className="content">
                        {row?.address?.formattedAddress}
                      </td>
                      <td className="content"></td>
                      <td className="content"></td>
                      <td
                        className="spanbtn"
                        onClick={() => handleDeletePos(row.id)}
                      >
                        Usuń
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}

      <Modal handleModal={openModal} close={() => setOpenModal(false)}>
        <UserForm user={user} close={() => setOpenModal(false)} />
      </Modal>
    </article>
  );
};

export default withMainLayout(User);
