export const COMPANY_REPORTS_REQUEST = "COMPANY_REPORTS_REQUEST";
export const COMPANY_REPORTS_SUCCESS = "COMPANY_REPORTS_SUCCESS";
export const COMPANY_REPORTS_FAILURE = "COMPANY_REPORTS_FAILURE";

export const USER_REPORTS_REQUEST = "USER_REPORTS_REQUEST";
export const USER_REPORTS_SUCCESS = "USER_REPORTS_SUCCESS";
export const USER_REPORTS_FAILURE = "USER_REPORTS_FAILURE";

export const POINTS_REPORTS_REQUEST = "POINTS_REPORTS_REQUEST";
export const POINTS_REPORTS_SUCCESS = "POINTS_REPORTS_SUCCESS";
export const POINTS_REPORTS_FAILURE = "POINTS_REPORTS_FAILURE";

export const PARTNER_REPORTS_REQUEST = "PARTNER_REPORTS_REQUEST";
export const PARTNER_REPORTS_SUCCESS = "PARTNER_REPORTS_SUCCESS";
export const PARTNER_REPORTS_FAILURE = "PARTNER_REPORTS_FAILURE";

export const companyReports = () => {
  return {
    type: COMPANY_REPORTS_REQUEST,
  };
};

export const userReports = () => {
  return {
    type: USER_REPORTS_REQUEST,
  };
};

export const pointsReports = () => {
  return {
    type: POINTS_REPORTS_REQUEST,
  };
};

export const partnershipReports = () => {
  return {
    type: PARTNER_REPORTS_REQUEST,
  };
};
