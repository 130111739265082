import React, { FunctionComponent } from "react";

import "./sort.scss";

interface ISort {
  sortlist?: (t: string) => void;
}

const Sort: FunctionComponent<ISort> = ({ sortlist }) => {
  const handleChange = (e: any) => {
    sortlist && sortlist(e.target.value);
  };

  return (
    <div className="sorting">
      <label htmlFor="sorting" className="clabel">
        Sortowanie
      </label>
      <select name="sorting" onChange={handleChange}>
        <option value="dec">Alfabetycznie(A-Z)</option>
        <option value="asc">Alfabetycznie(Z-A)</option>
      </select>
    </div>
  );
};

export default Sort;
