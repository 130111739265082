import React from "react";
import withMainLayout from "./../../layouts/index";
import PdfIcon from "../../../images/pdf.svg";

import FileItem from "../../components/fileItem";
import "./reports.scss";

const Reports: React.FC = () => {
  return (
    <article className="reports-page">
      <div className="header">
        <h3>Raporty</h3>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-3">
            <FileItem
              title="Generuj raport z firm"
              icon={PdfIcon}
              name="company"
            ></FileItem>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3">
            <FileItem
              title="Generuj raport z punktów sprzedaży"
              icon={PdfIcon}
              name="pos"
            ></FileItem>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3">
            <FileItem
              title="Generuj raport z użytkowników"
              icon={PdfIcon}
              name="user"
            ></FileItem>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3">
            <FileItem
              title="Generuj raport z programu poleceń"
              icon={PdfIcon}
              name="partnership_program"
            ></FileItem>
          </div>
        </div>
      </div>
    </article>
  );
};

export default withMainLayout(Reports);
