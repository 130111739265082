import React, { useState } from "react";
import logo from "../../../images/logo.png";
import Button from "../Button/Button";
import { NavLink } from "react-router-dom";
import Icon from "./../Icon";

import "./sidebar.scss";

interface menuItem {
  link: string;
  label: string;
  icon: string;
}

const MenuItem = ({ link, label, icon }: menuItem) => (
  <NavLink to={link} activeClassName="active">
    <Icon name={icon} /> <span>{label}</span>
  </NavLink>
);

const menu: Array<menuItem> = [
  // { link: "/", label: "Panel główny", icon: "speed" },
  { link: "/users", label: "Użytkownicy", icon: "shield" },
  { link: "/companies", label: "Baza firm", icon: "certificate" },
  { link: "/points", label: "Punkty sprzedazy", icon: "certificate" },
  { link: "/reports", label: "Raporty", icon: "speed" },
];

const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState(false);

  return (
    <nav className={activeMenu ? "sidebar active" : "sidebar"}>
      <div className="sidebar--header">
        <img src={logo} className="App-logo" alt="logo" />
        <Button
          className={
            activeMenu ? "button hamburger active" : "button hamburger"
          }
          onClick={() => setActiveMenu(!activeMenu)}
        >
          <span></span>
          <span></span>
          <span></span>
        </Button>
      </div>
      <ul className="sidebar--menu">
        {menu.map((item, index) => (
          <li key={index}>
            <MenuItem link={item.link} label={item.label} icon={item.icon} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Sidebar;
