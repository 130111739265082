import React from "react";
import ReactModal from "react-modal";

import "./modal.scss";

interface IModal {
  handleModal: boolean;
  close: () => void;
}

const Modal: React.FC<IModal> = ({ handleModal, close, children }) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={handleModal}
      contentLabel="Modal #2 Global Style Override Example"
      onRequestClose={() => close()}
      className="Modal"
      overlayClassName="Overlay"
    >
      <div className="modal-body">
        <div className="container">
          <div className="row">
            <div className="col-md-12">{children}</div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;
