export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";
export const GET_COMPANIES_REQUEST = "GET_COMPANIES_REQUEST";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAILURE = "GET_COMPANIES_FAILURE";
export const SEARCH_COMPANIES_REQUEST = "SEARCH_COMPANIES_REQUEST";
export const SEARCH_COMPANIES_SUCCESS = "SEARCH_COMPANIES_SUCCESS";
export const SEARCH_COMPANIES_FAILURE = "SEARCH_COMPANIES_FAILURE";
export const GET_COMPANY_INFO_REQUEST = "GET_COMPANY_INFO_REQUEST";
export const GET_COMPANY_INFO_SUCCESS = "GET_COMPANY_INFO_SUCCESS";
export const GET_COMPANY_INFO_FAILURE = "GET_COMPANY_INFO_FAILURE";
export const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILURE = "ADD_COMPANY_FAILURE";
export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";

export const updateCompany = (id: number, data: any) => {
  return {
    method: "PUT",
    type: UPDATE_COMPANY_REQUEST,
    data,
    id,
  };
};

export const addCompany = (data?: object) => {
  return {
    method: "POST",
    type: ADD_COMPANY_REQUEST,
    data,
  };
};

export const getCompanies = () => {
  return {
    type: GET_COMPANIES_REQUEST,
  };
};

export const searchCompanies = (query: string | undefined) => {
  return {
    method: "GET",
    type: SEARCH_COMPANIES_REQUEST,
    id: `search?query=${query}`,
  };
};

export const getCompanyInfo = (data?: object) => {
  return {
    method: "GET",
    type: GET_COMPANY_INFO_REQUEST,
    data,
  };
};

export const deleteCompany = (id: number) => {
  return {
    method: "DELETE",
    type: DELETE_COMPANY_REQUEST,
    id,
  };
};
