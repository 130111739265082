import { takeLatest } from "redux-saga/effects";
import * as ACTION from "./actions";
import { createSaga } from "../sagas";

export function* updatePointsSaga() {
  yield takeLatest(
    ACTION.UPDATE_POINTS_REQUEST,
    createSaga("UPDATE_POINTS", "/pos", "PUT")
  );
}

export function* getPointsSaga() {
  yield takeLatest(
    ACTION.GET_POINTS_REQUEST,
    createSaga("GET_POINTS", "/pos", "GET")
  );
}

export function* searchPointsSaga() {
  yield takeLatest(
    ACTION.SEARCH_POINTS_REQUEST,
    createSaga("SEARCH_POINTS", "/pos/search")
  );
}

export function* addPointsSaga() {
  yield takeLatest(
    ACTION.ADD_POINTS_REQUEST,
    createSaga("ADD_POINTS", "/pos", "POST")
  );
}

export function* deletePointsSaga() {
  yield takeLatest(
    ACTION.DELETE_POINTS_REQUEST,
    createSaga("DELETE_POINTS", "/pos", "DELETE")
  );
}
