import React, { useEffect } from "react";
import { Formik, Form, Field, FormikErrors } from "formik";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import { RootState, AuthApiResult } from "../../redux/reducers";
import useLocalStorage from "../../hooks/useLocalStorage";
import { login } from "../../redux/auth/actions";
import displayMessage from "../../helpers/message";
import routes from "../../components/Routes/routes";
// import Swal from "sweetalert2";
// import { withSwalInstance } from "sweetalert2-react";
// import withReactContent from "sweetalert2-react-content";
// import ResetForm from "./../resetForm/";

import "./login-form.scss";

interface MyFormValues {
  email: string;
  password: string;
}

const initialValues: MyFormValues = {
  email: "",
  password: "",
};

const LoginForm: React.FC = () => {
  const history = useHistory();
  const dispatch: Dispatch = useDispatch();
  const [, setItem] = useLocalStorage("token", "");

  const auth = useSelector<RootState, AuthApiResult>((state) => state.Auth);

  useEffect(() => {
    if (auth.logged && auth.token) {
      setItem(auth.token);
      history.push(routes.users);
    }
    if (auth.data?.amended) {
      //   Swal.close();
      displayMessage("Hasło zostało wysłane na adres e-mail", "success");
    }
    if (auth.error) {
      displayMessage(auth.error, "error");
    }
  }, [auth, history, setItem]);

  return (
    <Formik
      initialValues={initialValues}
      validate={(values: MyFormValues) => {
        const errors: FormikErrors<MyFormValues> = {};
        if (!values.email) {
          errors.email = "To pole jest wymagane";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "Nieprawidłowy adres email";
        }
        if (!values.password) {
          errors.password = "To pole jest wymagane";
        }
        return errors;
      }}
      onSubmit={(values, actions) => {
        // console.log(values);
        dispatch(login(values));
        actions.setSubmitting(false);
      }}
    >
      {() => (
        <Form className="login-form">
          <Field name="email">
            {({ field, meta }: { field: any; meta: any }) => (
              <fieldset>
                <input
                  type="text"
                  placeholder="Adres e-mail"
                  className={meta.error && "invalid"}
                  {...field}
                />
                {meta.touched && meta.error && (
                  <div className="error">{meta.error}</div>
                )}
              </fieldset>
            )}
          </Field>
          <Field name="password">
            {({ field, meta }: { field: any; meta: any }) => (
              <fieldset>
                <input
                  type="password"
                  placeholder="Hasło"
                  className={meta.error && "invalid"}
                  {...field}
                />
                {meta.touched && meta.error && (
                  <div className="error">{meta.error}</div>
                )}
              </fieldset>
            )}
          </Field>
          <div>
            <Button className="button link">Zapomniałeś hasła?</Button>
            <Button
              className={auth.loading ? "submit loading" : "submit"}
              type="submit"
            >
              Zaloguj się
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
