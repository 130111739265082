import React from "react";
import API from "../../services/api";
import { saveAs } from "file-saver";
import "./file-item.scss";

interface Props {
  Cdate?: string;
  title: string;
  name: string;
  icon?: string;
  Cnumber?: string;
  Cfile?: string;
  onClick?: (e: any) => void;
}

const FileItem: React.FC<Props> = ({
  Cdate,
  title,
  name,
  icon,
  Cnumber,
  Cfile,
}) => {
  const onClickHandler = (name: string) => {
    fetch(`${API.url}/report/${name}`, { headers: API.headers }).then(
      (response: any) => {
        if (response.ok) {
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, "raport.xlsx");
        }
      }
    );
  };

  return (
    <div className="file-item" onClick={() => onClickHandler(name)}>
      <div className="item-row">
        <div className="mark">
          {icon && <img src={icon} className="icon" alt="file icon" />}
          {Cnumber && <span>{Cnumber}</span>}
        </div>
        <div>
          <span className="date-subtitle">{Cdate}</span>
          <h4>{title}</h4>
        </div>
      </div>
      {Cfile && (
        <div className="item-row">
          <span>Pobierz</span>
        </div>
      )}
    </div>
  );
};

export default FileItem;
