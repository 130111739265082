import React from "react";
import logo from "../../../images/logo.png";
import login from "../../../images/login.png";
import LoginForm from "../../layouts/Login";
import { Link } from "react-router-dom";
// import routes from "../../routes";

import "./login.scss";

const Login: React.FC = () => {
  return (
    <article className="login-page">
      <div className="row no-gutters">
        <div className="col-12 col-lg-5">
          <div className="content">
            <img src={logo} className="App-logo" alt="logo" />

            <h3>Zaloguj się do panelu administracyjnego</h3>
            <LoginForm />
          </div>
        </div>
        <div className="col-lg-7 ">
          <div className="img-wrapper">
            <img src={login} alt="login page background" />
          </div>
        </div>
      </div>
    </article>
  );
};

export default Login;
