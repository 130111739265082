import React, { FunctionComponent } from "react";
import "./search.scss";

interface SearchTypes {
  placholder?: string;
  label?: string;
  onchange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Search: FunctionComponent<SearchTypes> = ({
  placholder = "Wpisz imię, nazwisko, nazwę firmy lub punkt sprzedaży",
  label = "Szukaj",
  onchange
}) => {
  return (
    <div className="search-field">
      <input
        onChange={onchange}
        type="text"
        name="search"
        placeholder={placholder}
      />
      <label htmlFor="search">{label}</label>
    </div>
  );
};

export default Search;
