import { takeLatest } from "redux-saga/effects";
import * as ACTION from "./actions";
import { createSaga } from "../sagas";

export function* activateUserSaga() {
  yield takeLatest(
    ACTION.ACTIVATE_USER_REQUEST,
    createSaga("ACTIVATE_USER", "/users/unlock", "PUT")
  );
}

export function* blockUserSaga() {
  yield takeLatest(
    ACTION.BLOCK_USER_REQUEST,
    createSaga("BLOCK_USER", "/users/lock", "PUT")
  );
}

export function* updateUserSaga() {
  yield takeLatest(
    ACTION.UPDATE_USER_REQUEST,
    createSaga("UPDATE_USER", "/users", "PUT")
  );
}

export function* getUserSaga() {
  yield takeLatest(ACTION.GET_USER_REQUEST, createSaga("GET_USER", "/users"));
}

export function* deleteUserSaga() {
  yield takeLatest(
    ACTION.DELETE_USER_REQUEST,
    createSaga("DELETE_USER", "/users", "DELETE")
  );
}

export function* addUserTagSaga() {
  yield takeLatest(
    ACTION.ADD_USER_TAG_REQUEST,
    createSaga("ADD_USER_TAG", "/users/tag", "PUT")
  );
}

export function* deleteUserTagSaga() {
  yield takeLatest(
    ACTION.DELETE_USER_TAG_REQUEST,
    createSaga("DELETE_USER_TAG", "/tags", "DELETE")
  );
}

export function* deleteUserPosSaga() {
  yield takeLatest(
    ACTION.DELETE_USER_POS_REQUEST,
    createSaga("DELETE_USER_POS", "/pos", "DELETE")
  );
}
