import React from "react";
import Routes from "./components/Routes";
import "./../scss/app.scss";

const App: React.FC = () => {
  return (
    <div className="App">
      <Routes />
    </div>
  );
};

export default App;
