import React from "react";

import Button from "../../components/Button/Button";
import Tag from "../../components/Tag";

import "./addtags.scss";

interface IAddTags {
  tags: Array<ITag>;
  addNew: () => void;
}

interface ITag {
  id: number;
  name: string;
}

const AddTags: React.FC<IAddTags> = ({ tags, addNew }) => {
  return (
    <div className="tags">
      <span className="tags__title">Wybierz tagi</span>
      <div className="tags__list">
        {!!tags &&
          tags.map((tag: ITag) => (
            <Tag key={tag.id} user={true} text={tag.name} tagid={tag.id} />
          ))}
      </div>
      <div className="tags__add">
        <Button className="tag" onClick={() => addNew()}>
          Dodaj nowy
        </Button>
      </div>
    </div>
  );
};

export default AddTags;
