import { all, call, put } from "redux-saga/effects";
import { DefaultApiAction } from "./reducers";
import API from "./../services/api";
import { getUsersSaga, searchUsersSaga, getUsersTagsSaga } from "./users/sagas";
import {
  activateUserSaga,
  blockUserSaga,
  updateUserSaga,
  getUserSaga,
  deleteUserSaga,
  addUserTagSaga,
  deleteUserTagSaga,
  deleteUserPosSaga,
} from "./user/sagas";
import { loginSaga } from "./auth/sagas";
import {
  updateCompanySaga,
  getCompaniesSaga,
  searchCompaniesSaga,
  deleteCompanySaga,
  addCompanySaga,
  getCompanyInfoSaga,
} from "./companies/sagas";

import {
  updatePointsSaga,
  getPointsSaga,
  searchPointsSaga,
  addPointsSaga,
  deletePointsSaga,
} from "./points/sagas";

import {
  companyReportsSaga,
  userReportsSaga,
  pointsReportsSaga,
  partnershipReportsSaga,
} from "./reports/sagas";

export function createSaga(prefix: string, endpoint: string, method = "GET") {
  return function*(action: DefaultApiAction) {
    let data: DefaultApiAction = Object.assign({}, action);
    const paramsEndpoint = action.id ? `${endpoint}/${action.id}` : endpoint;

    try {
      const response = yield call(
        API.call,
        paramsEndpoint,
        method,
        action.data
      );

      const responseBody = yield call([response, "json"]);

      if (response.status >= 400) {
        yield put({
          type: `${prefix}_FAILURE`,
          data,
          errorCode: response.status,
          payload: responseBody,
        });
      } else {
        yield put({ type: `${prefix}_SUCCESS`, payload: responseBody });
      }
    } catch (e) {
      yield put({ type: `${prefix}_FAILURE`, message: e });
    }
  };
}

export default function* rootSaga(getState: any) {
  yield all([
    getUsersSaga(),
    searchUsersSaga(),
    activateUserSaga(),
    getCompaniesSaga(),
    loginSaga(),
    blockUserSaga(),
    updateUserSaga(),
    deleteCompanySaga(),
    getCompanyInfoSaga(),
    getUserSaga(),
    addCompanySaga(),
    searchCompaniesSaga(),
    updateCompanySaga(),
    deleteUserSaga(),
    addUserTagSaga(),
    updatePointsSaga(),
    getPointsSaga(),
    searchPointsSaga(),
    addPointsSaga(),
    deletePointsSaga(),
    getUsersTagsSaga(),
    getUsersTagsSaga(),
    deleteUserTagSaga(),
    deleteUserPosSaga(),
    companyReportsSaga(),
    userReportsSaga(),
    pointsReportsSaga(),
    partnershipReportsSaga(),
  ]);
}
