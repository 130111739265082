import React, { useEffect, useState } from "react";
import withMainLayout from "./../../layouts/index";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState, DefaultApiResult } from "../../redux/reducers";
import { getUsers, searchUsers, getUsersTags } from "../../redux/users/actions";

import {
  ACTIVATE_USER_SUCCESS,
  UPDATE_USER_SUCCESS
} from "../../redux/user/actions";
import PageFilters from "../../layouts/PageFilters";
import UserItem from "../../layouts/UserItem";
import Modal from "../../components/Modal";
import UserForm from "../../layouts/UserForm";
import { eventBus } from "../../helpers/eventBus";
import "./users.scss";

const Users: React.FC = () => {
  const [sortType, setSortType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch: Dispatch = useDispatch();
  const users = useSelector<RootState, DefaultApiResult>(state => state.Users);
  const user = useSelector<RootState, DefaultApiResult>(state => state.User);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    const sortedList = users?.data?.content.sort((a: any, b: any) =>
      sortType === "asc"
        ? a.firstName.localeCompare(b.firstName)
        : b.firstName.localeCompare(a.firstName)
    );
  }, [sortType]);

  useEffect(() => {
    eventBus(UPDATE_USER_SUCCESS).then((payload: any) => {
      if (payload) {
        dispatch(getUsers());
      }
    });
    eventBus(ACTIVATE_USER_SUCCESS).then((payload: any) => {
      if (payload) {
        dispatch(getUsers());
      }
    });
  }, [dispatch, user.loading]);

  // send request  collected from pageFilters componennt
  const sendUserFilters = (query?: string, selectedTags?: any) => {
    const tagIds = selectedTags.map((tag: any) => tag.id);
    dispatch(searchUsers(query, tagIds));
  };

  const addNew = () => {
    setSelectedUser(null);
    setOpenModal(true);
  };

  const editUserFromList = (user: any) => {
    setSelectedUser(user);
    setOpenModal(true);
  };

  const handleSortList = (t: string) => {
    setSortType(t);
  };

  return (
    <article>
      <PageFilters
        loading={users.loading}
        title="Użytkownicy"
        tags={users.tags}
        sortlist={(t: string) => handleSortList(t)}
        addNew={{
          show: true,
          text: "Dodaj nowego",
          newaction: () => addNew()
        }}
        handle={{
          text: "Szukaj",
          handleaction: (q: string, tags: any) => sendUserFilters(q, tags)
        }}
      />

      <div className="users-list">
        {users?.data?.content &&
          users?.data?.content.length > 0 &&
          users?.data?.content.map((user: any) => (
            <UserItem
              getuser={user => editUserFromList(user)}
              key={user.id}
              data={user}
            />
          ))}
      </div>

      <Modal handleModal={openModal} close={() => setOpenModal(false)}>
        <UserForm user={selectedUser} close={() => setOpenModal(false)} />
      </Modal>
    </article>
  );
};

export default withMainLayout(Users);
