import React, { FunctionComponent } from "react";
import Button from "../../components/Button/Button";
import {
  activateUser,
  selectedUser,
  DELETE_USER_SUCCESS,
} from "../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import Trash from "./../../../images/trash.svg";
import { Dispatch } from "redux";
import { RootState, DefaultApiResult } from "../../redux/reducers";
import { useHistory } from "react-router-dom";
import { deleteUser } from "../../redux/user/actions";
import DetailCol from "../../components/DetailCol";
import Swal from "sweetalert2";
import { withSwalInstance } from "sweetalert2-react";
import withReactContent from "sweetalert2-react-content";
import { eventBus } from "../../helpers/eventBus";
import "./useritem.scss";

interface IUserItem {
  data?: IData;
  getuser: (user: any) => void;
}

interface IData {
  id: number;
  firstName: string;
  lastName: string;
  locked: boolean;
  company: ICompany;
}

interface ICompany {
  name: string;
  phoneNumber: string;
  salePoint: any;
  createDate: number;
  salePoints: any;
}

const MySwal = withReactContent(Swal);

const UserItem: FunctionComponent<IUserItem> = ({ data, getuser }) => {
  const dispatch: Dispatch = useDispatch();
  const user = useSelector<RootState, DefaultApiResult>((state) => state.User);
  const history = useHistory();

  const handleDetailsClick = (data: any) => {
    history.push(`/users/${data.id}`);
    dispatch(selectedUser(data));
  };

  const handleDeleteUser = (id: any) => {
    return withSwalInstance(
      MySwal.fire({
        buttonsStyling: false,
        title: "Czy na pewno chcesz usunąć użytkownika?",
        icon: "error",
        iconHtml: `<img src="${Trash}" />`,
        showCancelButton: true,
        confirmButtonText: "Tak, usuń",
        cancelButtonText: "Anuluj",
      }).then((result) => {
        if (result.value) {
          dispatch(deleteUser(id));
          eventBus(DELETE_USER_SUCCESS).then(
            Swal.fire("Użytkownik usunięty", "", "success")
          );
        }
      })
    );
  };

  return (
    <div className="user-item">
      <header className={`user-item__header ${data?.locked && "locked"}`}>
        {data?.firstName} {data?.lastName}
      </header>
      <div className="row">
        <DetailCol
          heading="Telefon kontaktowy"
          content={data?.company?.phoneNumber}
          col="3"
        />
        <DetailCol heading="Firma" content={data?.company?.name} col="3" />
        <DetailCol
          heading="Punkt Sprzedazy"
          content={data?.company?.salePoints[0]?.name}
          col="3"
        />
        <DetailCol
          heading="Data zgłoszenia"
          content={data?.company?.createDate}
          col="3"
        />
      </div>
      <div className="user-item__options">
        {data?.locked && (
          <Button
            loading={user?.loading}
            className="active"
            onClick={() => dispatch(activateUser(data?.id))}
          >
            Aktywuj
          </Button>
        )}

        <Button onClick={() => handleDetailsClick(data)}>Szczegóły</Button>
        <Button onClick={() => data && getuser(data)}>Edytuj</Button>
        <Button onClick={() => handleDeleteUser(data?.id)}>Usuń</Button>
      </div>
    </div>
  );
};

export default UserItem;
