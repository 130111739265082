import React from "react";
import Sidebar from "./../components/Sidebar";

import "./layout.scss";

const withMainLayout = (WrapperdComponent: any) => (props: any) => (
  <div className="main-page">
    <div className="sidebar-wrapper">
      <Sidebar />
    </div>
    <div className="content">
      <WrapperdComponent {...props} />
    </div>
  </div>
);

export default withMainLayout;
