import * as ACTION from "./actions";
import { DefaultApiResult, DefaultApiAction } from "../reducers";

export const INIT_STATE = {
  loading: false,
  error: false,
  data: [],
};

export default function reducer(
  state: DefaultApiResult = INIT_STATE,
  action: DefaultApiAction
): DefaultApiResult {
  if (state === undefined) {
    return INIT_STATE;
  }
  switch (action.type) {
    case ACTION.COMPANY_REPORTS_REQUEST:
    case ACTION.USER_REPORTS_REQUEST:
    case ACTION.POINTS_REPORTS_REQUEST:
    case ACTION.PARTNER_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ACTION.COMPANY_REPORTS_SUCCESS:
    case ACTION.USER_REPORTS_SUCCESS:
    case ACTION.POINTS_REPORTS_SUCCESS:
    case ACTION.PARTNER_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };

    case ACTION.COMPANY_REPORTS_FAILURE:
    case ACTION.USER_REPORTS_FAILURE:
    case ACTION.POINTS_REPORTS_FAILURE:
    case ACTION.PARTNER_REPORTS_FAILURE:
      console.log(action);
      return {
        ...state,
        loading: false,
        error: action.payload.errors,
      };
    default:
      return state;
  }
}
