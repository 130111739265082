import React from "react";
import { Formik, Form, Field, ErrorMessage, FormikErrors } from "formik";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { updateUser } from "../../redux/user/actions";
import { updateCompany } from "../../redux/companies/actions";
import { RootState, DefaultApiResult } from "../../redux/reducers";
import Select from "../../components/Select";
import "./userform.scss";

interface IUserForm {
  user?: any;
  close: () => void;
}

interface MyFormValues {
  email: string;
}

const UserForm: React.FC<IUserForm> = ({ close, user }) => {
  const dispatch: Dispatch = useDispatch();
  const reduxUser = useSelector<RootState, DefaultApiResult>(
    state => state.User
  );

  const userObj = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || ""
  };

  const companyObj = {
    company: user?.company?.name || "",
    phoneNumber: user?.company?.phoneNumber || "",
    street: user?.company?.headquarterAddress?.street || "",
    buildingNum: user?.company?.headquarterAddress?.buildingNumber || "",
    apartmentNum: user?.company?.headquarterAddress?.apartmentNumber || "",
    postCode: user?.company?.headquarterAddress?.postCode || "",
    city: user?.company?.headquarterAddress?.city || "",
    county: user?.company?.headquarterAddress?.county || ""
  };

  return (
    <div className="user-form">
      <h1>{!!user ? "Edytuj użytkownika" : "Dodaj użytkownika"}</h1>

      <Formik
        initialValues={{ ...userObj, ...companyObj }}
        validate={values => {
          const errors: FormikErrors<MyFormValues> = {};
          if (!values.email) {
            errors.email = "Wymagane";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Zły adres email";
          }
          return errors;
        }}
        onSubmit={(values: any, { setSubmitting }) => {
          const companyadress = {
            city: values.city,
            street: values.street,
            buildingNumber: values.buildingNum,
            apartmentNumber: values.apartmentNum,
            postCode: values.postCode,
            county: values.county
          };

          if (!!user) {
            dispatch(updateUser(user.id, values));
            const correspondeceType = user?.company?.useHeadquarterAddressOnly;

            !!user.company &&
              dispatch(
                updateCompany(user.company.id, {
                  ...user.company,
                  name: values.company,
                  headquarterAddress: companyadress
                })
              );
          }

          setSubmitting(false);
          close();
        }}
      >
        {({ isSubmitting }) => (
          <Form className="user-form__form">
            <fieldset>
              <legend>Dane osobowe</legend>

              <div className="row">
                <div className="col-md-4">
                  <div className="input-field">
                    <label htmlFor="firstName">Imię</label>
                    <Field type="text" name="firstName" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field">
                    <label htmlFor="lastName">Nazwisko</label>
                    <Field type="text" name="lastName" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field">
                    <label htmlFor="company">Wybierz firmę</label>
                    <Field type="text" name="company" />
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Dane kontaktowe</legend>
              <div className="row">
                <div className="col-md-4">
                  <div className="input-field">
                    <label htmlFor="phoneNumber">Numer telefonu</label>
                    <Field type="number" name="phoneNumber" />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="input-field">
                    <label htmlFor="email">Adres e-mail</label>
                    <Field type="email" name="email" />
                    <ErrorMessage name="email" component="div" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="input-field">
                    <label htmlFor="street">Ulica</label>
                    <Field type="text" name="street" />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="input-field">
                    <label htmlFor="buildingNum">Numer budynku</label>
                    <Field type="text" name="buildingNum" />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="input-field">
                    <label htmlFor="apartmentNum">Numer lokalu</label>
                    <Field type="text" name="apartmentNum" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field">
                    <label htmlFor="postCode">Kod pocztowy</label>
                    <Field type="text" name="postCode" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="input-field">
                    <label htmlFor="city">Miasto</label>
                    <Field type="text" name="city" />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="input-field">
                    <label htmlFor="county">Powiat</label>
                    <Field type="text" name="county" />
                  </div>
                </div>
              </div>
            </fieldset>

            <Button onClick={() => close()} type="button" className="tag big">
              Anuluj
            </Button>
            <Button
              loading={reduxUser.loading}
              disabled={isSubmitting}
              type="submit"
            >
              {!!user ? "Zapisz" : "Dodaj"}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserForm;
