import React, { useEffect, useState } from "react";
import withMainLayout from "./../../layouts/index";
import Trash from "./../../../images/trash.svg";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import { Dispatch } from "redux";
import { eventBus } from "../../helpers/eventBus";
import { RootState, DefaultApiResult } from "../../redux/reducers";
import {
  getCompanies,
  searchCompanies,
  deleteCompany,
  UPDATE_COMPANY_SUCCESS,
  DELETE_COMPANY_SUCCESS,
} from "../../redux/companies/actions";
import PageFilters from "../../layouts/PageFilters";
import Modal from "../../components/Modal";
import CompanyForm from "../../layouts/CompanyForm";
import { format } from "date-fns";
import displayMessage from "./../../helpers/message";
import Swal from "sweetalert2";
import { withSwalInstance } from "sweetalert2-react";
import withReactContent from "sweetalert2-react-content";
import "./companies.scss";

const columns: Array<string> = [
  "ID",
  "Nazwa",
  "NIP",
  "Adres siedziby",
  "Punkty sprzedaży",
  "Data zgłoszenia",
  "Operacje",
];

const MySwal = withReactContent(Swal);

const Companies: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch: Dispatch = useDispatch();
  const users = useSelector<RootState, DefaultApiResult>(
    (state) => state.Users
  );
  const companies = useSelector<RootState, DefaultApiResult>(
    (state) => state.Companies
  );

  useEffect(() => {
    dispatch(getCompanies());
    eventBus(UPDATE_COMPANY_SUCCESS).then((payload: any) => {
      if (payload) {
        dispatch(getCompanies());
      }
    });
    if (companies.error) {
      displayMessage(companies.error, "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // send request  collected from pageFilters componennt
  const sendUserFilters = (query?: string) => {
    dispatch(searchCompanies(query));
  };

  const handleDeleteUser = (id: any) => {
    return withSwalInstance(
      MySwal.fire({
        buttonsStyling: false,
        title: "Czy na pewno chcesz usunąć firmę?",
        icon: "error",
        iconHtml: `<img src="${Trash}" />`,
        showCancelButton: true,
        confirmButtonText: "Tak, usuń",
        cancelButtonText: "Anuluj",
      }).then((result) => {
        if (result.value) {
          dispatch(deleteCompany(id));

          eventBus(DELETE_COMPANY_SUCCESS).then(
            displayMessage("Firma została usnięta", "success")
          );
          dispatch(getCompanies());
        }
      })
    );
  };

  const addNew = () => {
    setSelectedUser(null);
    setOpenModal(true);
  };

  const editUserFromList = (company: any) => {
    setSelectedUser(company);
    setOpenModal(true);
  };

  return (
    <article>
      <div className="companies-page">
        <PageFilters
          loading={users.loading}
          title="Baza firm"
          addNew={{
            show: true,
            text: "Dodaj nowego",
            newaction: () => addNew(),
          }}
          handle={{
            text: "Szukaj",
            handleaction: (q) => sendUserFilters(q),
          }}
        />
        {companies?.data?.content && companies?.data?.content.length > 0 && (
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr className="clabel">
                  {columns.map((column: any, index) => {
                    return <th key={index}>{column}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {companies?.data?.content
                  .filter((item) => item.deleted === false)
                  .map((row: any) => (
                    <tr key={row.id}>
                      <td className="content">{row.id}</td>
                      <td className="content">{row.name}</td>
                      <td className="content">{row?.nip}</td>
                      <td className="content">
                        {row?.headquarterAddress?.formattedAddress},{" "}
                        {row?.headquarterAddress?.postCode}{" "}
                        {row?.headquarterAddress?.city}
                      </td>
                      <td className="content">
                        {row?.salePoints?.length > 0 ? "List" : ""}
                      </td>
                      <td className="content">
                        {format(new Date(row.createDate), "yyyy-MM-dd HH:mm")}
                      </td>
                      <td className="spanbtn">
                        <Button
                          className="button link"
                          onClick={() => editUserFromList(row)}
                        >
                          Edytuj
                        </Button>
                        <Button className="button link button--green">
                          Aktywuj
                        </Button>
                        <Button
                          className="button link"
                          onClick={() => handleDeleteUser(row?.id)}
                        >
                          Usuń
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Modal handleModal={openModal} close={() => setOpenModal(false)}>
        <CompanyForm company={selectedUser} close={() => setOpenModal(false)} />
      </Modal>
    </article>
  );
};

export default withMainLayout(Companies);
