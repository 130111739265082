export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const getUsers = () => {
  return {
    method: "GET",
    type: FETCH_USERS_REQUEST
  };
};

export const SEARCH_USERS_REQUEST = "SEARCH_USERS_REQUEST";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_FAILURE = "SEARCH_USERS_FAILURE";

export const searchUsers = (query: string | undefined = "", tags: any) => {
  let params;

  if (query !== "") {
    params = `?query=${query}`;
  }
  if (tags.length > 0) {
    params = `?tags=${tags}`;
  }
  if (query !== "" && tags.length > 0) {
    params = `?query=${query}&?tags=${tags}`;
  }

  return {
    method: "GET",
    type: SEARCH_USERS_REQUEST,
    id: params
  };
};

export const GET_USERS_TAGS_REQUEST = "GET_USERS_TAGS_REQUEST";
export const GET_USERS_TAGS_SUCCESS = "GET_USERS_TAGS_SUCCESS";
export const GET_USERS_TAGS_FAILURE = "GET_USERS_TAGS_FAILURE";

export const getUsersTags = () => {
  return {
    method: "GET",
    type: GET_USERS_TAGS_REQUEST
  };
};
