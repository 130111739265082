import * as ACTION from "./actions";
import { DefaultApiResult, DefaultApiAction } from "../reducers";

export const INIT_STATE = {
  loading: false,
  error: false,
  data: [],
  tags: []
};

export default function reducer(
  state: DefaultApiResult = INIT_STATE,
  action: DefaultApiAction
): DefaultApiResult {
  if (state === undefined) {
    return INIT_STATE;
  }
  switch (action.type) {
    case ACTION.FETCH_USERS_REQUEST:
    case ACTION.SEARCH_USERS_REQUEST:
    case ACTION.GET_USERS_TAGS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ACTION.FETCH_USERS_SUCCESS:
    case ACTION.SEARCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };

    case ACTION.GET_USERS_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        tags: action.payload
      };

    case ACTION.FETCH_USERS_FAILURE:
    case ACTION.SEARCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.errors
      };
    default:
      return state;
  }
}
