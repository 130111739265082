import React from "react";
import correct from "../../../images/correct.svg";
import "./status.scss";

interface IStatus {
  text: string;
  locked: boolean;
}

const Status: React.FC<IStatus> = ({ text, locked }) => {
  return (
    <span className={`status ${locked && "unactive"}`}>
      {text} {!locked && <img src={correct} alt="" />}
    </span>
  );
};

export default Status;
