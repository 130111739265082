import React from "react";
import { useHistory } from "react-router-dom";
import arrow from "../../../images/rightArr.svg";

import "./back.scss";

interface IBack {
  loading?: boolean;
}

const Back: React.FC<IBack> = ({ loading }) => {
  const history = useHistory();
  console.log({ loading });
  return (
    <div className="back-arrow" onClick={() => history.goBack()}>
      {loading ? "Ładowanie..." : <img src={arrow} alt="arrow" />}
    </div>
  );
};

export default Back;
