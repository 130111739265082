import Swal from "sweetalert2";
import { withSwalInstance } from "sweetalert2-react";

const displayMessage = (
  title: any,
  icon: "success" | "error" | "warning" | "info" | "question" = "info"
) => {
  return withSwalInstance(
    Swal.fire({
      toast: true,
      position: "top-end",
      icon: icon,
      customClass: {
        container: "info-alert-popup",
      },
      title: Array.isArray(title)
        ? `<ul>${[...title]
            .map(
              (item) =>
                `<li>${
                  item.field ? `${item.field} - ${item.message}` : item.message
                } </li>`
            )
            .join("")}</ul>`
        : title,
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 2500,
    })
  );
};

export default displayMessage;
