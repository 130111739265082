import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, FormikErrors } from "formik";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { updateUser } from "../../redux/user/actions";
import {
  updateCompany,
  getCompanyInfo,
  addCompany,
} from "../../redux/companies/actions";
import { RootState, DefaultApiResult } from "../../redux/reducers";
// import Select from "../../components/Select";
import "./companyform.scss";

interface IUserForm {
  company?: any;
  close: () => void;
}

interface MyFormValues {
  email: string;
}

const CompanyForm: React.FC<IUserForm> = ({ close, company }) => {
  const userObj = {
    nip: company?.nip || "",
    name: company?.name || "",
    lastName: company?.user?.lastName || "",
    firstName: company?.user?.firstName || "",
    company: company?.user?.company?.name || "",
    phoneNumber: company?.phoneNumber || "",
    email: company?.user?.email || "",
    street: company?.headquarterAddress?.street || "",
    buildingNumber: company?.headquarterAddress?.buildingNumber || "",
    apartmentNum: company?.headquarterAddress?.apartmentNumber || "",
    postCode: company?.headquarterAddress?.postCode || "",
    city: company?.headquarterAddress?.city || "",
    county: company?.headquarterAddress?.county || "",
  };

  const [formValues, setFromValues] = useState(userObj);

  const dispatch: Dispatch = useDispatch();
  const reduxUser = useSelector<RootState, DefaultApiResult>(
    (state) => state.User
  );

  const reduxCompany = useSelector<RootState, DefaultApiResult>(
    (state) => state.Companies
  );

  const getGusInfo = (nip: string) => {
    dispatch(getCompanyInfo({ nip }));
    setFromValues({ ...formValues, nip });
  };

  useEffect(() => {
    if (reduxCompany.data?.info?.name) {
      setFromValues({
        ...formValues,
        name: reduxCompany.data.info.name,
        city: reduxCompany.data.info.city,
        county: reduxCompany.data.info.county,
        postCode: reduxCompany.data.info.postCode,
        street: reduxCompany.data.info.street,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxCompany.data, reduxCompany.error]);

  return (
    <div className="user-form">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <h1>{!!company ? "Edytuj firmę" : "Nowa firma"}</h1>
        </div>
      </div>

      <Formik
        initialValues={formValues}
        enableReinitialize
        validate={(values) => {
          const errors: FormikErrors<MyFormValues> = {};
          if (!values.email) {
            errors.email = "Wymagane";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Zły adres email";
          }
          return errors;
        }}
        onSubmit={(values: any, { setSubmitting }) => {
          const companyadress = {
            city: values.city,
            street: values.street,
            buildingNumber: values.buildingNumber,
            apartmentNumber: values.apartmentNum,
            postCode: values.postCode,
            county: values.county,
          };

          if (!!company) {
            dispatch(
              updateCompany(company.id, {
                ...values,
                headquarterAddress: companyadress,
                useHeadquarterAddressOnly: true,
              })
            );
            dispatch(updateUser(company.user.id, values));
          } else {
            dispatch(
              addCompany({
                ...values,
                headquarterAddress: companyadress,
                useHeadquarterAddressOnly: true,
              })
            );
          }

          setSubmitting(false);
          close();
        }}
      >
        {({ isSubmitting, values }) => (
          <Form className="company-form__form">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <fieldset>
                  <legend>Dane firmy</legend>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-field">
                        <label htmlFor="nip">nip</label>
                        <Field type="text" name="nip" />
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="input-field">
                        <label htmlFor="name">Nazwa</label>
                        <Field type="text" name="name" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 offset-md-6 gus-wrapper">
                      <Button
                        className="submit gus-btn"
                        onClick={() => getGusInfo(values.nip)}
                        type="button"
                      >
                        Pobierz z GUS
                      </Button>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <legend>Adres siedziby</legend>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-field">
                        <label htmlFor="street">Ulica</label>
                        <Field type="text" name="street" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-field">
                        <label htmlFor="buildingNumber">Numer budynku</label>
                        <Field type="text" name="buildingNumber" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-field">
                        <label htmlFor="apartmentNum">Numer lokalu</label>
                        <Field type="text" name="apartmentNum" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-field">
                        <label htmlFor="city">Miasto</label>
                        <Field type="text" name="city" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field">
                        <label htmlFor="postCode">Kod pocztowy</label>
                        <Field type="text" name="postCode" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-field">
                        <label htmlFor="county">Powiat</label>
                        <Field type="text" name="county" />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <legend>Dane reprezentanta</legend>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-field">
                        <label htmlFor="firstName">Imię</label>
                        <Field type="text" name="firstName" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field">
                        <label htmlFor="lastName">Nazwisko</label>
                        <Field type="text" name="lastName" />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <legend>Dane kontaktowe</legend>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-field">
                        <label htmlFor="phoneNumber">Numer telefonu</label>
                        <Field type="text" name="phoneNumber" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field">
                        <label htmlFor="email">Adres e-mail</label>
                        <Field type="email" name="email" />
                        <ErrorMessage name="email" component="div" />
                      </div>
                    </div>
                  </div>
                </fieldset>

                <Button
                  onClick={() => close()}
                  type="button"
                  className="tag big"
                >
                  Anuluj
                </Button>
                <Button
                  loading={reduxUser.loading}
                  disabled={isSubmitting}
                  type="submit"
                >
                  {!!company ? "Zapisz" : "Dodaj"}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyForm;
