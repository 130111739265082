import React, { FunctionComponent, useState, useEffect } from "react";
import Tag from "../../components/Tag";
import Search from "../../components/Search";
import Sort from "../../components/Sort";
import Button from "../../components/Button/Button";

import "./pagefilters.scss";

interface IPageFilters {
  addNew?: IAddNew;
  search?: ISearch;
  handle?: IHandle;
  title: string;
  loading?: boolean;
  tags?: any;
  sortlist?: any;
}

interface IAddNew {
  show: boolean | undefined;
  text: string | undefined;
  newaction: () => void;
}

interface ISearch {
  label: string;
  placeholder: string;
  searchaction: () => void;
}

interface IHandle {
  text: string;
  handleaction: (s: string, t: any) => void;
}

const options = [
  { value: "1", label: "Sklep wielobranżowy" },
  { value: "2", label: "Sklep monopolowy" },
  { value: "3", label: "Kiosk" },
  { value: "4", label: "Salon prasowy" },
  { value: "5", label: "Stacja paliw" },
  { value: "6", label: "Sklep spożywczy" },
];

const PageFilters: FunctionComponent<IPageFilters> = ({
  addNew,
  handle,
  title,
  loading,
  tags,
  sortlist,
}) => {
  const [query, setQuery] = useState("");
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const sendFilters = () => {
    handle?.handleaction(query, selectedTags);
  };

  const handleSelectTag = (tag: any) => {
    if (!tag.active) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      const removedTag = selectedTags.filter((ctag: any) => ctag.id !== tag.id);
      setSelectedTags(removedTag);
    }
  };

  return (
    <div className="header">
      <header>
        <h3>{title}</h3>
        {addNew?.show && (
          <Button className="aqua" onClick={addNew?.newaction}>
            {addNew?.text}
          </Button>
        )}
      </header>

      <div className="row header__wrapper">
        <div className="col-md-12 col-lg-7 col-xl-8">
          <Search onchange={searchChange} />
        </div>
        <div className="col-sm-6 col-lg-3 col-xl-2">
          <Sort sortlist={(t: string) => sortlist(t)} />
        </div>
        <div
          className="col-sm-6 col-lg-2 col-xl-2"
          style={{ textAlign: "right" }}
        >
          <Button loading={loading} onClick={sendFilters}>
            {handle?.text}
          </Button>
        </div>
      </div>

      <div className="header__tags-list">
        {tags && tags.length > 0 && (
          <>
            <span>Tagi</span>
            {tags &&
              tags.map((tag: any) => (
                <Tag
                  id={tag.id}
                  setSelected={(tag: any) => handleSelectTag(tag)}
                  text={tag.name}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default PageFilters;
