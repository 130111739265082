import Users from "./users/reducer";
import User from "./user/reducer";
import Companies from "./companies/reducer";
import Auth from "./auth/reducer";
import Points from "./points/reducer";
import Reports from "./reports/reducer";

export interface RootState {
  Users: DefaultApiResult;
  User: DefaultApiResult;
  Auth: AuthApiResult;
  Companies: DefaultApiResult;
  Points: DefaultApiResult;
  Reports: DefaultApiResult;
}

export interface DefaultApiResult {
  loading: boolean;
  error: any;
  data: any;
  tags?: any;
}

export interface AuthApiResult {
  loading: boolean;
  logged: boolean;
  error?: any;
  data?: any;
  user?: any;
  token: string | null;
}

export interface DefaultApiAction {
  id?: string | number;
  type: string;
  error?: any;
  payload?: any;
  data?: DefaultApiAction;
}

export default {
  Users,
  User,
  Auth,
  Companies,
  Points,
  Reports,
};
