import { takeLatest } from "redux-saga/effects";
import * as ACTION from "./actions";
import { createSaga } from "../sagas";

export function* companyReportsSaga() {
  yield takeLatest(
    ACTION.COMPANY_REPORTS_REQUEST,
    createSaga("COMPANY_REPORTS", "/report/company")
  );
}

export function* userReportsSaga() {
  yield takeLatest(
    ACTION.USER_REPORTS_REQUEST,
    createSaga("USER_REPORTS", "/report/user")
  );
}

export function* pointsReportsSaga() {
  yield takeLatest(
    ACTION.POINTS_REPORTS_REQUEST,
    createSaga("POINTS_REPORTS", "/report/pos")
  );
}

export function* partnershipReportsSaga() {
  yield takeLatest(
    ACTION.PARTNER_REPORTS_REQUEST,
    createSaga("PARTNER_REPORTS", "/report/partnership_program")
  );
}
