const routes = {
  home: "/dashboard",
  users: "/users",
  user: "/users/:id",
  login: "/login",
  companies: "/companies",
  points: "/points",
  reports: "/reports",
};

export default routes;
