import React, { FunctionComponent, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { deleteUserTag } from "../../redux/user/actions";
import "./tag.scss";

interface TagTypes {
  text: string;
  id?: any;
  tagid?: number;
  user?: boolean;
  setSelected?: (tag: any) => void;
}

const Tag: FunctionComponent<TagTypes> = ({
  text,
  setSelected,
  id,
  user,
  tagid
}) => {
  const [active, setActive] = useState<boolean>(false);
  const dispatch: Dispatch = useDispatch();

  const handleActive = () => {
    setActive(!active);
    setSelected && setSelected({ id, name: text, active });
  };

  const handleDelete = (id: number | undefined) => {
    dispatch(deleteUserTag(id));
  };

  return (
    <div className={`tag ${active && "active"}`} onClick={() => handleActive()}>
      {text}{" "}
      {user && (
        <button onClick={() => handleDelete(tagid)} className="delete">
          x
        </button>
      )}
    </div>
  );
};

export default Tag;
