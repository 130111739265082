import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware, connectRouter } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { EventBus, createEventBusMiddleware } from "redux-event-bus";

import reducers from "../redux/reducers";
import rootSaga from "../redux/sagas";

const persistConfig = {
  key: "root",
  storage
};

const evBus = new EventBus();
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [
  sagaMiddleware,
  routeMiddleware,
  createEventBusMiddleware(evBus)
];
export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history)
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

let persistor = persistStore(store);

sagaMiddleware.run(rootSaga, null);
export { store, persistor, history, evBus };
